.dealerSelect {
  .card-align-center {
    text-align: center;
  }

  .mat-card-header, .mat-card-content, .mat-card-actions {
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .mat-dialog-title, .mat-dialog-content, .mat-dialog-actions {
    display: flex;
    justify-content: center;
    text-align: center;
  }
}

.dash-billboard {
  margin-bottom: 12px;

  .mat-card {
    padding: 10px 20px;
    background: inherit;
    color: white;
  }

  .mat-card-title {
    margin-bottom: 10px;
    font-size: 15px;
  }

  .mat-card-content {
    padding: inherit;
  }

  .mat-card-footer {
    margin: inherit;
    background-color: wheat;
  }

  .billboard-footer {
    font-size: 11.5px;
    margin-top: 5px;
  }
}

.sd-billboard {
  margin-bottom: 12px;

  // .mat-scorecard {
  //   padding: initial !important;
  //   color: white;
  // }

  .mat-card {
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    background: inherit;
    color: white;
  }


  .mat-card-title {
    font-size: 14px;
    padding: 10px 15px;
    margin: inherit;

    .ellipses-dropdown {
      color: var(--brand-primary);
      margin-right: -5px;
    }
  }

  .mat-card-content {
    padding: inherit;
  }

  .mat-card-footer {
    margin: inherit;
    padding: 10px;
    font-size: 11.5px;
    color: black;
    background-color: white;
    min-height: 35px;
    border-top: 1px solid #cccccc;

    table {
      width: 100%;

      td {
        width: 50%;

        &:last-of-type {
          text-align: right;
        }
      }

      .prev-metric {
        text-align: right;
      }
    }
  }

  .billboard-red {
    background-color: $billboard-red;
  }

  .billboard-green {
    background-color: $billboard-green;
  }

  .billboard-yellow {
    background-color: $billboard-yellow;
  }

  .billboard-blue {
    background-color: $billboard-blue;
  }

  .billboard-grey {
    background-color: $billboard-grey;
  }

  .text-red {
    color: $billboard-red;
  }

  .text-green {
    color: $billboard-green;
  }

  .text-yellow {
    color: $billboard-yellow;
  }

  .text-blue {
    color: $billboard-blue;
  }

  .text-grey {
    color: $billboard-grey;
  }
}

.sd-mini-billboard {
  margin-bottom: 12px;

  .mat-card {
    padding: inherit;
    background: inherit;
    color: white;
  }

  .mat-card-content {
    padding: inherit;
    margin: inherit;
  }

  .mat-card-footer {
    margin: inherit;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 11.5px;
    color: black;
    min-height: 35px;

    table {
      width: 100%;

      td {
        width: 50%;
        border: none !important;

        &:last-of-type {
          text-align: right;
        }
      }

      .prev-metric {
        text-align: right;
      }
    }
  }

  .billboard-red {
    background-color: $billboard-red;
  }

  .billboard-green {
    background-color: $billboard-green;
  }

  .billboard-yellow {
    background-color: $billboard-yellow;
  }

  .billboard-blue {
    background-color: $billboard-blue;
  }

  .billboard-grey {
    background-color: $billboard-grey;
  }

  .text-red {
    color: $billboard-red;
  }

  .text-green {
    color: $billboard-green;
  }

  .text-yellow {
    color: $billboard-yellow;
  }

  .text-blue {
    color: $billboard-blue;
  }

  .text-grey {
    color: $billboard-grey;
  }
}

.flex-billboard-container{
  margin-bottom: 1rem;
  display: flex;
  flex-wrap: wrap;
}

.flex-billboard-card div {
  flex: 100%;
}

.flex-billboard-category{
  padding: 0rem 0rem 0rem 0rem;
}


.flex-billboard-card-50 {
  /*flex: 0 1 auto;*/
  flex-basis: 50%;
  /*flex-direction: column;
  display: flex;*/
  flex-wrap: wrap;
  /*margin: .5rem;*/
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  padding: 0rem 0.5rem 0rem 0.5rem;
  -webkit-transition: all 250ms;
  -moz-transition: all 250ms;
  transition: all 250ms;
  padding-bottom: 15px;

  .mat-card {
    padding: unset;
  }

  .mat-card-title {
    font-size: 15px;
    padding: 10px 20px;
    margin: 0px;
  }

  .mat-card-content {
    padding: 0px;
    margin-bottom: 0px;

    .focus-metric-value {
      position: relative;
      font-size: 2em;
      z-index: 1;
    }

    .focus-metric-label {
      position: relative;
      top: -20px;
      font-size: 0.8em;
    }
  }

  .mat-card-footer {
    margin: 0px;
    padding: 10px;
    font-size: 11.5px;
    color: black;
    background-color: white;
    min-height: 35px;

    .secondary-metric-value {
      font-size: 2.0em;
    }

    .secondary-metric-label {
      font-size: 1.2em;
    }

    table {
      width: 100%;

      td {
        width: 33%;
        text-align: center;
      }

      .prev-metric {
        text-align: right;
      }
    }
  }
}


