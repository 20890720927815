.email-app {
  display: flex;
  flex: 1;
  flex-direction: row;
  overflow-x: hidden;

  nav {
    flex: 0 0 200px;
    order: -1;
    padding: 15px;
    border-right: 1px solid $border-color;

    .btn-block {
      margin-bottom: 15px;
    }

    ul.nav {
      flex-direction: column;

      li.nav-item {
        margin: 0;
        position:relative;

        a.nav-link {
          font-size: 12px;
          font-weight: 400;
          text-transform: uppercase;
          text-decoration: none;
          display: block;
          padding: 0 10px;

          i {
            width: 20px;
            text-align: center;
            margin: 0 10px 0 0;
            font-size: 14px;
          }

          .tag {
            float: right;
            margin-top: 16px;
            margin-left: 10px;
          }
        }

        a.nav-link {
          line-height: 40px;
          color: $body-color;
          border-bottom: 1px solid $border-color;
        }
      }
    }
  }

  main {
    flex: 1;
    padding: 15px;

    .toolbar {
      margin: -15px -15px 15px -15px;
      padding: 15px;
      border-bottom: 1px solid $border-color;
    }

    ul.messages-list {
      list-style: none;
      margin: 15px -15px 0 -15px;
      padding: 15px 15px 0 15px;

      li {
        cursor: pointer;
        margin-bottom: 10px;
        padding: 10px;

        a {
          color: black;
          &:hover {
            text-decoration: none;
          }
        }

        &.unread {
          .header, .title {

            font-weight: bold;

          }
        }

        &:hover {
          border: 1px solid $border-color;
          padding: 9px;

          .action {
            color: $border-color;
          }
        }

        .header {
          margin: 0 0 5px 0;

          .from {
            width: 49.9%;
            white-space: nowrap;
            overflow:hidden !important;
            text-overflow: ellipsis;
          }

          .date {
            width: 50%;
            text-align: right;
            float: right;
          }

        }

        .title {
          margin: 0 0 5px 0;
          white-space: nowrap;
          overflow:hidden !important;
          text-overflow: ellipsis;
        }

        .description {
          font-size: 12px;
          padding-left: 29px;
        }

        .action {
          display: inline-block;
          width: 16px;
          text-align: center;
          margin-right: 10px;
          color: $border-color;

          .fa-check-square-o {
            margin: 0 -1px 0 1px;
          }

          .fa-square {
            float: left;
            margin-top: -16px;
            margin-left: 4px;
            font-size: 11px;
            color: white;
          }

          .fa-star.bg {
            float: left;
            margin-top: -16px;
            margin-left: 3px;
            font-size: 12px;
            color: white;
          }

        }
      }
    }

    .message {


      .message-title {
        margin-top: 30px;
        padding-top: 10px;
        font-weight: bold;
        font-size: 14px;
      }

      .header {
        margin: 20px 0 30px 0;
        padding: 10px 0 10px 0;
        border-top: 1px solid $border-color;
        border-bottom: 1px solid $border-color;

        .avatar {
          height: 34px;
          width: 34px;
          float: left;
          margin-right: 10px;
        }

        i {
          margin-top: 1px;
        }

        .from {
          display: inline-block;
          width: 50%;
          font-size: 12px;
          margin-top: -2px;
          color: $border-color;

          span {
            display: block;
            font-size: 14px;
            font-weight: bold;
          }
        }

        .date {
          display: inline-block;
          width: 29%;
          text-align: right;
          float: right;
          font-size: 12px;
          margin-top: 18px;
        }

      }

      .attachments {
        border-top: 3px solid $gray-lightest;
        border-bottom: 3px solid $gray-lightest;
        padding: 10px 0px;
        margin-bottom: 20px;
        font-size: 12px;

        ul {
          list-style: none;
          margin: 0 0 0 -40px;


          li {
            margin: 10px 0;

            .tag {
              padding: 2px 4px;
            }

            span.quickMenu {
              float: right;
              text-align: right;

              .fa {
                padding: 5px 0 5px 25px;
                font-size: 14px;
                margin: -2px 0px 0px 5px;
                color: $border-color;
              }
            }
          }
        }
      }
    }
  }
}
