.trafficDetail, .competitiveSales {
  label.mat-button-toggle-label {
    display: inline !important;
  }
}

.search-dropdown {
  width: 100%;
  text-align: left !important;

  i {
    margin-right: 10px;
    margin-bottom: 2px;
  }
}

.mat-raised-button {
  overflow: hidden;

  .pad-icon-left {
    padding-left: 7px;
    padding-bottom: 2px;
  }

  .pad-icon-right {
    padding-right: 7px;
    padding-bottom: 2px;
  }
}