.details-view {
  .panel-body-header {
    margin-bottom: 0px !important;
    border-bottom: 1px solid #c0c0c0;
  }

  .col-container {
    margin-top: 0px !important;
    height: 100%;
  }

  .panel-body {
    padding-bottom: 0px;
  }


  .col-labels {
    height: 100%;
    padding-top: 10px;
    //background-color: #f2f2f2;
  }

  .col-values {
    height: 100%;
    padding-top: 10px;
  }


  .field-label {
    text-align: right;
    display: inline-block;
    width: 100%;
  }

  .field-value {
    font-weight: normal;
    color: black;
    white-space: nowrap;
    text-overflow: ellipsis;
    /*margin-top:5px;*/
  }
}
