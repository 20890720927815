.app-footer {
  min-height: $footer-height;
  padding: 0 $spacer;
  line-height: $footer-height;
  color: $footer-color;
  background: $footer-bg;
  @include borders($footer-borders);

  .text-primary {
    color: var(--brand-primary) !important;
  }
}
