
.mat-menu-content {
  padding-bottom: 0px;
}

.mat-card .channel-menu {
  box-shadow: none !important;
}

.card-header, .card-footer {
  height: 50px;
  padding-top: .8rem !important;
  padding-left: 1.25rem;
  padding-right: 1.25rem;

  .actions {
    /*margin-top: -.8rem !important;*/
  }

  select {
    min-width: 130px;
    margin-top: -3px;
  }
}

.card-no-border {
  border: none !important;
}

.card-header {
  font-weight: bold;
  font-size: 15px;

  .mat-raised-button {
    max-height: 32px;
  }

  .mat-form-field-infix {
    border: 0px;
    padding: 0.3em 0 !important;
    width: 150px;
  }
}

.card-block .card-block {
  max-height: 400px;
  overflow: auto;
}

.card-block-no-bottom-padding {
  padding-bottom: 0em !important;
}

.tall-card-block {
  max-height: 700px !important;
  height: 700px !important;
  overflow: auto;
}

.card-header .card-actions a {
  transform: rotate(-45deg);
  height: 57px;
  margin-top: -26px !important;
  margin-right: -23px !important;
  cursor: default;
  font-size: 16px;


  .fa {
    transform: rotate(45deg);
    margin-top: 7px !important;
    margin-left: -28px;
    padding: 3px;
    cursor: pointer;

    &:hover {
      color: rgb(74, 74, 74);
      text-shadow: 0px 0px 18px $gray-dark;
    }
  }
}

.base-card {
  // margin-left: 10px !important;
  // margin-right: 10px !important;
  border-top: 3px solid $theme-color;
}

.base-card-block {
  background-color: #f5f5f5 !important;
}

.base-card-header {
}

.card-header {

}

.card-billboard {
  //margin-bottom: unset !important;

  .card-block {
    padding-top: 0px;
    padding-bottom: 0px;

    h1 {
      font-size: 3rem;
      margin-bottom: 0px;
    }

    .fa {
      font-size: 2.1875rem;
      vertical-align: middle !important;
    }

    .billbord-metric-label {
      padding-left: 8px;
      font-size: 16px;
    }
  }
}


.target-card {
  table {
    width: 100%;
    margin: 10px 0px 10px 0px;

    td {
      font-size: 2.5em;
      text-align: center;

      .fa {
        margin-top: -5px;
      }
    }

    .box-label {
      font-size: 12px;
    }
  }
}

.sales-performance-card {
  overflow-y: auto;
  height: 400px;
  table {
    margin-top: 1em;
  }
}

.dig-ad-benchmark {
  overflow-y: auto;
  max-height: 400px;
  padding-bottom: 1em;

}

.green {
  color: #5cb85c;
}

.yellow {
  color: #e8d500;
}

.red {
  color: #d9534f;
}

.blue {
  color: #248eb6;
}

.gray {
  color: #6b6b6b;
}

.fill-canvas {
  // hard-coded values represent filter bar height and any addional space we want around the expanded card
  height: calc(100vh - var(--navbar-brand-height) - #{$breadcrumb-line-height} - 50px - #{$footer-height} - 115px);
  max-height: unset !important;
}

.fill-width {
  flex: 0 0 100%;
  max-width: unset !important;
}
