//
// Mixins
// ------------------------------


// Utilities

@mixin size($width, $height)
{
	width: $width;
	height: $height;
}
@mixin square($size)
{
	@include size($size, $size);
}
@mixin border-top-radius($radius)
{
	border-top-right-radius: $radius;
	border-top-left-radius: $radius;
}
@mixin border-right-radius($radius)
{
	border-bottom-right-radius: $radius;
	border-top-right-radius: $radius;
}
@mixin border-bottom-radius($radius)
{
	border-bottom-right-radius: $radius;
	border-bottom-left-radius: $radius;
}
@mixin border-left-radius($radius)
{
	border-bottom-left-radius: $radius;
	border-top-left-radius: $radius;
}


// Vendor Prefixes

@mixin animation($animation)
{
	-webkit-animation: $animation;
	-o-animation: $animation;
	animation: $animation;
}
@mixin box-sizing($boxmodel)
{
	-webkit-box-sizing: $boxmodel;
	-moz-box-sizing: $boxmodel;
	box-sizing: $boxmodel;
}
