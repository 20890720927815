.details-edit {
  .col-container {
    margin-top: 0px !important;
    height: 100%;
  }

  .col-labels {
    height: 100%;
    padding-top: 10px;
    background-color: #f2f2f2;
    text-align: right;

    .row {
      height: 50px;
    }
  }

  .col-values {
    height: 100%;
    padding-top: 10px;

    .row {
      height: 50px;
    }

    label.ui-checkbox {
      margin-top: 5px;
    }
  }

  .col-import {
    min-height: 100px;
    height: 100%;
    padding-top: 10px;
    border-left: 1px solid #d70915 !important;
  }

  .col-cta {
    margin-top: 10px;
  }
}
