/*
 *  Usage:
 *
      <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
      </div>
 *
 */
@use "sass:math";
@import "../variables";

.sk-three-bounce {
  $animationDuration: 1.4s;
  $delayRange: 0.32s;
  margin: $spinkit-spinner-margin;
  width: $spinkit-size * 2;
  text-align: center;

  .sk-child {
    width: math.div($spinkit-size,2);
    height: math.div($spinkit-size,2);
    background-color: $spinkit-spinner-color;

    border-radius: 100%;
    display: inline-block;
    animation: sk-three-bounce $animationDuration ease-in-out 0s infinite both;
  }

  .sk-bounce1 {
    animation-delay: -$delayRange;
  }

  .sk-bounce2 {
    animation-delay: math.div(-$delayRange,2);
  }
}

@keyframes sk-three-bounce {

  0%,
  80%,
  100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1.0);
  }
}