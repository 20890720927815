// Core Admin Variables

$enable-sidebar-nav-rounded: false;

$border-color: $gray;
$layout-transition-speed: 0.15s ease-in-out;
$text-color: #383a35;
$text-color-light: #676a6c;
$theme-color: var(--brand-primary);

// Social Colors
$facebook: #3b5998;
$twitter: #00aced;
$linkedin: #4875b4;
$google-plus: #d34836;
$flickr: #ff0084;
$tumblr: #32506d;
$xing: #026466;
$github: #4183c4;
$html5: #e34f26;
$openid: #f78c40;
$stack-overflow: #fe7a15;
$youtube: #b00;
$css3: #0170ba;
$dribbble: #ea4c89;
$google-plus: #bb4b39;
$instagram: #517fa4;
$pinterest: #cb2027;
$vk: #45668e;
$yahoo: #400191;
$behance: #1769ff;
$dropbox: #007ee5;
$reddit: #ff4500;
$spotify: #7ab800;
$vine: #00bf8f;
$foursquare: #1073af;
$vimeo: #aad450;

// Navbar

$navbar-height:                     60px; // moved to theme vars
$navbar-bg:                         #fff;
$navbar-border: (
    bottom: (
        size:                       1px,
        style:                      solid,
        color:                      #adadad
    )
);
$navbar-brand-width:                200px; // moved to theme vars
$navbar-brand-height:               60px;  // moved to theme vars
$navbar-brand-bg:                   $gray-dark-darken-10;
$navbar-brand-logo:                 url('/assets/images/logo.png');
$navbar-brand-logo-mobile:          url('/assets/images/logo.png');
$navbar-brand-logo-size:            auto;
$navbar-brand-border: (
    bottom: (
        size:                       1px,
        style:                      solid,
        color:                      $gray-dark-darken-10
    )
);
$navbar-brand-margin-left:          10px;

$navbar-color:                      $text-color;
$navbar-hover-color:                $text-color;
$navbar-active-color:               $text-color;
// $navbar-disabled-color:             lighter($navbar-color, 20%); // was not being used

// Task sidebar
$task-sidebar-width:                400px;

// Sidebar

$sidebar-width:                     220px; // no longer used
$mobile-sidebar-width:              220px; // no longer used
$sidebar-padding:                   0;
$sidebar-minimized-width:           50px;
$sidebar-minimized-height:          $sidebar-minimized-width;
$sidebar-compact-width:             50px;
$sidebar-compact-height:            $sidebar-compact-width;
$sidebar-color:                     #fff;
$sidebar-bg:                        #000; // moved to theme vars
$sidebar-header-bg:                 rgba(0,0,0,.2);
$sidebar-footer-bg:                 rgba(0,0,0,.2);
$sidebar-borders: (
    right: (
        size:                       1px,
        style:                      solid,
        color:                      $border-color
    )
);

// Sidebar Navigation
// side bar reports
$sidebar-nav-child-bg: 	            #32312C;
$sidebar-nav-item-child-bg:         #767368;
$sidebar-nav-item-child-hover-bg:   #5b5950;
$sidebar-nav-item-child-active-bg:  #403e38;


// $sidebar-nav-color:                 var(--brand-primary); // TODO - was not being used
$sidebar-nav-title-padding-y:       .95rem;
$sidebar-nav-title-padding-x:       1rem;
$sidebar-nav-title-color:           $text-muted;
$sidebar-nav-link-padding-y:        .75rem;
$sidebar-nav-link-padding-x:        0.9rem;
$sidebar-nav-link-color:            #fff;
$sidebar-nav-link-bg:               transparent;
$sidebar-nav-link-icon-color:       #fff;
$sidebar-nav-item-color:            var(--brand-primary-lighten-10);

$sidebar-nav-link-borders: (
    bottom: (
        size:                       0px,
        style:                      solid,
        color:                      $border-color
    )
);
$sidebar-nav-link-hover-color:      #fff;
$sidebar-nav-link-hover-bg:         var(--sidebar-bg-darken-5);
$sidebar-nav-link-hover-icon-color: #fff !important;

$sidebar-nav-link-hover-borders:    0;
$sidebar-nav-link-active-color:     #fff;
$sidebar-nav-link-active-bg:        var(--sidebar-bg-darken-5);
$sidebar-nav-link-active-icon-color:#fff;

$sidebar-nav-link-active-borders:   0;

$sidebar-nav-dropdown-color:        $gray-dark;
// $sidebar-nav-dropdown-bg:           var(--sidebar-bg-darken-3); // was not being used
$sidebar-nav-dropdown-borders:      0;

// Secondary Nav
$secondary-nav-active-color:        #e4e4e4;
$secondary-nav-hover-color:         #efefef;

// Top Navigation
$top-nav-bg: #fff;
$top-nav-color:                     $body-color;
$top-nav-borders: (
  bottom: (
    size:                           1px,
    style:                          solid,
    color:                          $border-color
  )
);
$top-nav-ul-borders: (
  all: (
    size:                           1px,
    style:                          solid,
    color:                          $border-color
  )
);

$top-nav-hover-color:               #fff;
$top-nav-hover-bg:                  var(--brand-primary);
$top-nav-active-color:              #fff;
$top-nav-active-bg:                 var(--brand-primary);
$top-nav-height:                    var(--navbar-height) - 15px;

// Breadcrumb

$breadcrumb-borders: (
    bottom: (
        size:                       1px,
        style:                      solid,
        color:                      $border-color
    )
);
$breadcrumb-padding-y: .5rem;
$breadcrumb-line-height: 2em;
$breadcrumb-height: 50px;

// Aside

$aside-menu-width:                  250px;
$aside-menu-color:                  $gray-dark;
$aside-menu-bg:                     #fff;
$aside-menu-borders: (
  left: (
    size:                           1px,
    style:                          solid,
    color:                          $border-color
  )
);

$aside-menu-nav-padding-y:          .75rem;
$aside-menu-nav-padding-x:          1rem;

//content
$content-divider-height:            19px;

// Footer

$footer-height:                     50px;
$footer-bg:                         $gray-lightest;
$footer-color:                      $body-color;
$footer-borders: (
  top: (
    size:                           1px,
    style:                          solid,
    color:                          $border-color
  )
);

// Cards

$card-icon-bg:                      transparent;
$card-icon-color:                   $body-color;


$login-logo: url('/assets/images/PoweredByShift.png');
$login-brand-logo: url('/assets/images/login_logo.png');
$login-brand-logo-height: 60px;
$login-brand-logo-width: 60px;
// $login-shift-logo-width: 171px; not actually used
// $login-shift-logo-height: 20px; not actually used

// Billboards
$billboard-red:                     #c51230;
$billboard-green:                   #18b52d;
$billboard-yellow:                  #f9bb11;
$billboard-blue:                    #065799;
$billboard-grey:                    #404041;

// Report Tables
$table-bg-highlight: #9e9e9e;

// Combined Bar Charts
$combined-bar-green: #18b52d;
$combined-bar-blue: #224197;
$combined-bar-yellow: #f9bb11;
$combined-bar-red: #c51230;
$datatable-trend-toggle-select-width: 110px;

//Funnel Colors
$box-color: #3ccb9d;
$box-secondary-color:#229470;
$box-text-color: #fff;

/* 
 | The below .theme-vars style can be used to generate the values a new theme-vars.css 
 | file. Adjust any SASS variable values and then build. All color variants 
 | will be calculated and you can copy the output to a new file.
*/ 
// .theme-vars {
//   --brand-primary            : #{ $brand-primary };
//   --brand-primary-lighten-10 : #{ lighten($brand-primary, 10%) };
//   --brand-primary-lighten-40 : #{ lighten($brand-primary, 40%) };
//   --brand-primary-darken-5   : #{ darken($brand-primary, 5%) };
//   --brand-primary-darken-10  : #{ darken($brand-primary, 10%) };
//   --brand-primary-darken-12  : #{ darken($brand-primary, 12%) };
//   --brand-primary-opacity-25 : #{ hsla(hue($brand-primary), saturation($brand-primary), lightness($brand-primary), 0.25) };
//   --brand-primary-opacity-50 : #{ hsla(hue($brand-primary), saturation($brand-primary), lightness($brand-primary), 0.5) };

//   --brand-secondary: #{ if(global-variable-exists('brand-icon'), $brand-icon, var(--brand-primary)) };

//   --highlight                : #{ $brand-primary };
//   --highlight-tint           : #{ $brand-primary };

//   --navbar-height            : #{ $navbar-height };
//   --navbar-brand-width       : #{ $navbar-brand-width };
//   --navbar-brand-height      : #{ $navbar-brand-height };
//   --navbar-brand-margin-left : #{ $navbar-brand-margin-left };
//   --navbar-brand-logo-size   : #{ $navbar-brand-logo-size };
//   --navbar-brand-logo        : #{ $navbar-brand-logo };
//   --navbar-brand-logo-mobile : #{ $navbar-brand-logo-mobile };

//   --logo-bg-size             : auto; /* #TODO - fill in */
//   --login-logo-height        : auto; /* #TODO - fill in */
//   --login-logo-width         : 200px; /* #TODO - fill in */
//   --login-logo               : #{ $login-logo };
//   --login-brand-logo         : #{ $login-brand-logo };
//   --login-brand-logo-width   : #{ $login-brand-logo-width };
//   --login-brand-logo-height  : #{ $login-brand-logo-height };

//   --sidebar-width            : #{ $sidebar-width };
//   --mobile-sidebar-width     : #{ $mobile-sidebar-width };

//   --sidebar-bg               : #{ $sidebar-bg };
//   --sidebar-bg-darken-3      : #{ darken($sidebar-bg, 3%) };
//   --sidebar-bg-darken-5      : #{ darken($sidebar-bg, 5%) };
//   --sidebar-bg-darken-10     : #{ darken($sidebar-bg, 10%) };
//   --sidebar-bg-lighten-5     : #{ lighten($sidebar-bg, 5%) };

//   --combined-bar-green       : #{ $combined-bar-green };
//   --combined-bar-blue        : #{ $combined-bar-blue };
//   --combined-bar-yellow      : #{ $combined-bar-yellow };
//   --combined-bar-red         : #{ $combined-bar-red };
// }