.userMgmt {
  .form-search::before {
    display: block;
    width: 14px;
    height: 14px;
    content: "\f002";
    font-family: 'FontAwesome';
    background-position: -48px 0;
    position: absolute;
    top: -2px;
    left: 22px;
    opacity: .5;
    z-index: 1;
  }

  .user-row {
    margin: 0px;
  }

  .card-block {
    // Enable `flex-grow: 1` for decks and groups so that card blocks take up
    // as much space as possible, ensuring footers are aligned to the bottom.
    flex: auto;
    // padding: $card-spacer-x;
  }

  .search-box {
    margin-top: -10px;
    padding-left: 26px;
    margin-bottom: 12px;
  }
}
