// Columns are right-aligned by default. Use .sd-grid-col-header-left or .sd-grid-col-left to left-align content.
$color-primary: rgb(66, 133, 244);
$color-primary-muted: rgb(170, 203, 255);
$border-color: rgb(224, 224, 224);
.sd-grid-wrapper {
  position: relative;
  width: 100%;
  font-family: 'Roboto';
  background-color: white;
  color: rgb(33, 33, 33);
}

.sd-grid-header {
  height: 50px;
  width: calc(100% - 16px); // don't cover the scrollbar
  display: flex;
  color: rgb(117, 117, 117);
  border-top: 1px solid $border-color;
  border-bottom: 1px solid $border-color;
}

.sd-grid-header-pinned {
  flex: 0 0 auto;
  display: flex;
  position: relative;
}

.sd-grid-header-body {
  flex: 1 1 auto;
  overflow: hidden;
  display: flex;
}

.sd-grid-header-body-content {
  display: flex;
  flex: 1 0 auto;
  height: 100%;
  position: relative;
}

.sd-grid {
  height: 300px;
  display: flex;
  overflow: auto;
  overflow-x: hidden;
}

.sd-grid-pinned {
  flex: 0 0 auto;
  position: relative;
}

.sd-grid-separator {
  position: absolute;
  top: 0px;
  right: -5px;
  height: 100%;
  z-index: 999;
  width: 5px;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.2), rgba(255, 255, 255, 0.5));
}

.sd-grid-body {
  flex: 1 0 auto;
  display: flex;
  overflow-x: hidden;
  overflow-y: hidden;
}

.sd-grid-body-content {
  position: relative;
  flex: 1 0 auto;
}

.sd-grid-sidescroll-wrapper {
  width: calc(100% - 16px);
}

.sd-grid-sidescroll-wrapper {
  width: calc(100% - 16px);
  height: 18px;
  position: absolute;
  bottom: -17px;
  left: 0px;
}

.sd-grid-sidescroll {
  position: absolute;
  right: 0px;
  overflow-x: auto;
}

.sd-grid-sidescroll-content {
  height: 1px;
}

.sd-grid-row {
  display: flex;
  border-bottom: 1px solid $border-color;
}

.sd-grid-row-hover {
  background-color: rgb(238, 238, 238);
}

.sd-grid-col {
  // text-overflow ellipsis only seems to work when using display: block
  // so we can't use display: flex; align-items: center to vertically align our content. Bummer.
  // This also means that our div here won't take up the entire "cell". (top/bot auto margins)
  padding: 5px;
  flex: 1 1 auto;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: auto;
  margin-bottom: auto;
  text-align: right;
}

.sd-grid-col-header {
  // can't figure out a way to use flexbox to both vertically center its content, as well as text-overflow it
  // so our body cells will be display: block for text-truncation, and our header cells will be flexbox
  // so that they can use all the space and center contents, which will allow our borders to look nice
  padding: 5px;
  padding-right: 4px;
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid white;
  justify-content: flex-end;
  text-align: right;
}

.sd-grid-header-hovered .sd-grid-col-header {
  border-right: 1px solid $color-primary-muted;
}

.sd-grid-header-hovered .sd-grid-col-header:hover {
  border-right: 1px solid $color-primary;
}

.sd-grid-col-header .fa {
  padding-left: 4px;
  padding-right: 4px;
  order: -1;
}

.sd-grid-col-header-left {
  justify-content: flex-start;
  text-align: left;
}

.sd-grid-col-left {
  text-align: left;
}

.sd-grid-col-header-left .fa {
  order: initial;
}

.sd-grid-header-groups {
  border-bottom: none;
}

.sd-grid-header-groups .sd-grid-col-header {
  border-right: 1px solid $border-color;
  justify-content: center;
  cursor: initial;
}

.sd-grid-footer {
  background-color: rgb(245, 245, 245);
}

.accent-primary {
  color: $color-primary;
}

.accent-gray {
  color: rgb(158, 158, 158);
}

.accent-success {
  color: rgb(15, 157, 88);
}

.accent-danger {
  color: rgb(197, 57, 41);
}

.cell-bold {
  font-weight: bold;
}

// webkit scrollbar style
.fancy-scrollbar::-webkit-scrollbar {
  width: 17px;
  height: 17px;
  background-color: white;
}

.fancy-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgb(189, 189, 189);
}

.fancy-scrollbar::-webkit-scrollbar:hover {
  background-color: rgb(245, 245, 245);
}

.fancy-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: $color-primary;
}

.no-grow {
  flex: 0 1 auto;
}

.sd-no-show {
  display: none;
}

.sd-clickable {
  cursor: pointer;
}
