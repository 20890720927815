/*
  Classes to help with printing full pages to PDF with jsPDF and html2canvas
*/

/*
  Useful for scrollable elements. This class removes the height of the element
  so that html2canvas will show the whole thing instead of just the currently-visible
  section. Add this class before rendering the canvas and remove it in the .then()
  callback function to return it to its normal height
*/
.html2canvas-height {
  height: unset !important;
  max-height: unset !important;
}

/*
  Add slim borders to elements if they need it in the final PDF.
  Originally intended to add borders in place of box-shadows (which are not
  supported by html2canvas at the moment)
*/
.html2canvas-border-light {
  border: 1px solid $gray-light !important;
}

.html2canvas-border-medium {
  border: 1px solid $gray !important;
}

.html2canvas-border-dark {
  border: 1px solid $gray-dark !important;
}
