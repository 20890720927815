.help-body {
  margin-top: 15px;
  max-height: 650px;
  overflow-y: auto;
  div {
    margin-bottom: 10px;
  }

  .help-footnotes {
    font-style: italic;
  }
}


.help-icon:hover {
  color: #0275d8;
}

.close-icon:hover {
  color: #0275d8;
}
