.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.03);
}

.trafficSummary, .trafficDetail, .competitiveSales {

  .borderless td, .borderless th {
    border: none;
  }

  th.sortable {
    cursor: pointer;
  }

  .fixed-header {
    tbody {
      display: block;
      overflow-y: auto;
    }

    thead, tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed;
    }

    thead {
      width: calc( 100% - 2em );
      margin-top: -21px;
    }

    tbody td, thead > tr > th {
      border-bottom-width: 0;
    }
  }

  .campaign-performance, .dealer-performance {
    th:hover {
      cursor: pointer;
      color: #5e666f;
    }
  }

  .clickable:hover {
    cursor: pointer;
    color: #5e666f;
    font-weight: bold;
  }
}

.trafficSummary {
  .fixed-header {
    tbody {
      height: 259px;
    }
  }

  .tall-card-block {
    .fixed-header {
      tbody {
        height: 611px;
      }
    }
  }
}

.report-table-head {
  background-color: $theme-color;
  color: #fff;
  text-align: center;
}

.report-footnotes {
  padding-left: 20px;
  padding-bottom: 20px;
  background-color: $gray-lighter;
  font-style: italic;
}

.report {

  .highlight-date {
    background-color: $table-bg-highlight;
    color: #fff;
  }

  table {
    margin-bottom: unset !important;
  }

  thead {
    white-space: nowrap;
  }

  tbody {
    text-align: center;
    white-space: nowrap;
  }

  .filter-breadcrumbs-container {
    padding: 10px 20px 10px 20px;
    background-color: #f5f5f5 !important;
  }

  .th-hover:hover {
    color: #0275d8;
    cursor: pointer;
  }
}

.dealerPerformanceReport {

  .national-summary {
    background-color: #9e9e9e !important;
    color: #fff;
  }

  .region-summary {
    background-color: #596aa3 !important;
    color: #fff;
  }

  .district-summary {
    background-color: #8795bd !important;
    color: #fff;
  }
}

.lead-details {
  width: 100%;

  tr {
    td {
      width: 25%;
    }
  }
}

.center {
  text-align: center;
}

.transaction-report {
  max-height: calc(100vh - 348px) !important;
}

.sd-infinite-scroll-xy {
  max-height: 500px;
  overflow: auto;

  &.transaction-report {
    .data-table-header > th {
      min-width: 190px !important;
    }

    .data-table-header > th {
      &.small {
        min-width: 120px !important;
      }

      &.smaller {
        min-width: 100px !important;
      }

      &.smallest {
        min-width: 80px !important;
      }

      &.big {
        min-width: 310px !important;
      }

      .mat-sort-header-button {
        text-align: left !important;
      }
    }
  }

  table {
    border-collapse: separate !important;
    width: 100%;
  }

  &.first-column-pinned {
    .mat-table-sticky:first-child {
      border-right: 1px solid #cccccc;
      min-width: 350px;
    }
  }

  .first-data-column {
    padding-left: 12px;
  }

  .mat-table .highlight {
    background-color: #efefef;
  }

  .mat-header-cell {
    min-width: 200px;
    font-size: 14px;
    font-weight: bold;
    color: #616161 !important;
  }

  .data-table-header > th {
    min-width: 140px;
  }

  .first-sticky-end-column {
    border-left: 1px solid #dbdbdb !important;
  }
  /*Right-pinned columns, this is because the table is not respecting ngClass based on the column definition...it just freaks out and doesn't display properly*/
  .mat-column-totalDigitalSales, .mat-column-totalDigitalSalesContribution, .mat-column-retailSales {
    min-width: 110px !important;
  }

  .mat-cell {
    color: #616161 !important;
  }

  .hideRow {
    display: none !important;
  }

  .overflow-column-200 {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .nation-row .mat-cell {
    font-weight: bold;
    font-size: 14px !important;
  }

  .total-row {
    font-weight: bolder;
  }

  .orgcode2-row, .total-row, .lma-row .mat-cell {
    font-size: 14px !important;
  }

  .orgcode1-row, .detail-row .mat-cell {
    font-size: 14px !important;
  }

  .dealer-row .mat-cell {
    font-weight: normal;
    font-size: 12px !important;
  }

  .orgcode2, .lma, .total {
  }

  .orgcode1, .detail, .sourceGroup {
    padding-left: 32px !important;
  }

  .dealer, .source {
    padding-left: 40px !important;
  }

  .mat-paginator-sticky {
    bottom: 0px;
    left: 0px;
    position: sticky;
    z-index: 10;
    border-top: 1px solid #cccccc;
    /*width:1939px;*/
  }

  .mat-paginator-container {
    justify-content: flex-end !important;
  }

  .trend {
    display: inline-block;
    font-weight: normal;
    font-size: small;
    vertical-align: middle;
  }

  .overview-provider-kpi {
    .text-green {
      color: $billboard-green;
    }

    .text-yellow {
      color: $billboard-yellow;
    }

    .text-red {
      color: $billboard-red;
    }
  }

  .combined-chart-inner-div {
    height: 1.5em;
    display: inline-block;
    border-right: 1px solid white;

    &.green {
      background-color: var(--combined-bar-green);
    }

    &.blue {
      background-color: var(--combined-bar-blue);
    }

    &.yellow {
      background-color: var(--combined-bar-yellow);
    }

    &.red {
      background-color: var(--combined-bar-red);
    }
  }

  .left-border {
    border-left: 1px solid #ccc;
  }
}

.dealer-performance-table {
  .sd-infinite-scroll-xy {
    height: 353px;
  }
}
