$toast-color: #ffffff;
$toast-close-button-size: 20px;
$toast-padding: 20px;
$toast-background-color: #030303e6;
$toast-close-button-color: #ffffff;
$toast-width: 300px;

/* Toast Container */
toaster-container {
  position: absolute;
  z-index: 9999999;

  @media (max-width: 768px) { /* Mobile devices */
    width: $toast-width;
    left: 50%;
    transform: translateX(-50%);
  }

  @media (min-width: 769px) { /* Desktop */
    right: 12px;
  }
}

/* Toast Styles */
.toast {
  background-color: $toast-background-color;
  color: $toast-color;
  padding: $toast-padding;
  position: relative;
  border-radius: 4px;
  margin-bottom: 10px;
  height: auto;

  &-message {
    margin: 0;
  }

  &-success {
    background-color: #28a745;
  }

  &-error {
    background-color: #dc3545;
  }

  &-info {
    background-color: #17a2b8;
  }

  &-warning {
    background-color: #ffc107;
  }
}

.toast-close-button {
  position: absolute;
  top: 9px;
  right: 10px;
  font-size: $toast-close-button-size;
  color: $toast-close-button-color;
  background-color: transparent;
  border: 0;

  &:hover,
  &:focus {
    color: $toast-close-button-color;
  }
}
