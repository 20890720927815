.trafficSummary, .trafficDetail, .competitiveSales, .salesDashboard, .digitalAdvertising {

  .nav-link {
    padding-top: 0em;
    padding-bottom: 0em;
    font-weight: normal !important;
    text-decoration: none !important;

    &.active {
      font-weight: bold !important;
      color: $theme-color;
    }

    &:hover {
      text-decoration: underline !important;
    }
  }

  .nav {
    list-style: none;
    margin-right: 0;
  }

  .nav > li,
  .nav > li > a {
    display: inline-block;
    *display: inline;
    zoom: 1;
  }

  &.editable-input {
    min-width: 150px;
    text-align: center;
  }

  .stacked > li {
    display: list-item;
  }

  .stacked > li > a {
    display: block;
  }

  .flyout,
  .flyout-alt {
    vertical-align: middle;
    position: relative;

    ul {
      border: 1px solid $gray !important;
      background-color: $white !important;
    }
  }

  .flyout-alt {
    z-index: 99999;
  }

  li.flyout a {
    padding-top: 10px;
    padding-bottom: 9px;
    //border: 1px solid #cccccc !important;
    &.menu {
      //border-radius: 0.25rem;
      background-color: var(--brand-primary);
      color: $white;
      min-width: 150px;
      text-align: center;
      z-index: 999;

      &:hover {
        background-color: rgb(35, 93, 146);
        color: $white;
      }
    }
  }

  .flyout-content {
    z-index: 999999 !important;
    position: absolute;
    top: 100%;
    right: -99999px;
    overflow: hidden;
  }

  .flyout:hover > .flyout-content {
    right: 0;
  }

  .flyout-alt:hover > .flyout-content {
    top: 0;
    right: 100%;
  }

  .flyout:hover > .flyout-content,
  .flyout-alt:hover > .flyout-content {
    height: auto;
    overflow: visible;
  }

  .site-nav a {
    line-height: 1;
    padding: 1em;
    background-color: #fff;
    white-space: nowrap;
  }

  .site-nav .flyout:hover > a,
  .site-nav .flyout-alt:hover > a,
  .site-nav a:not(.menu):hover {
    //background-color: rgb(195, 220, 246);
    text-decoration: none;
  }

  .site-nav > li:first-child > a {
    border: none;
  }

  .site-nav .flyout-content a {
    z-index: 999999 !important;
    border-bottom-width: 1px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 150px;

    &.dealer {
      width: 200px !important;

      &:hover {
        background-color: var(--brand-primary) !important;
        color: $white;
      }
    }

    &.national {
      border-bottom: 1px solid $gray !important;

      &:hover {
        background-color: var(--brand-primary) !important;
        color: $white;
      }
    }

    &.region {
      &:hover {
        background-color: var(--brand-primary) !important;
        color: $white;
      }
    }
  }


  .channel-select {
    .flyout,
    .flyout-alt {
      vertical-align: middle;
      position: relative;
      z-index: 9999;

      ul {
        border: 1px solid $gray !important;
        background-color: $white !important;
      }
    }

    li.flyout a {
      padding-top: 10px;
      padding-bottom: 9px;
      //border: 1px solid #cccccc !important;
      &.menu {
        //border-radius: 0.25rem;
        background-color: var(--brand-primary);
        color: $white;
        min-width: 150px;
        text-align: center;

        &:hover {
          background-color: rgb(35, 93, 146);
          color: $white;
        }
      }
    }

    .flyout-content {
      background-color: $white;
      position: absolute;
      top: 100%;
      right: -99999px;
      overflow: hidden;
      width: 301px !important;
      border: 1px solid $gray;
    }

    .flyout:hover > .flyout-content {
      right: 0;
    }

    .flyout-alt:hover > .flyout-content {
      top: 0;
      right: 100%;
    }

    .flyout:hover > .flyout-content,
    .flyout-alt:hover > .flyout-content {
      height: auto;
      overflow: visible;
    }

    .site-nav a {
      line-height: 1;
      padding: 1em;
      background-color: #fff;
      white-space: nowrap;
    }

    .site-nav .flyout:hover > a,
    .site-nav .flyout-alt:hover > a,
    .site-nav a:not(.menu):hover {
      //background-color: rgb(195, 220, 246);
      text-decoration: none;
    }

    .site-nav > li:first-child > a {
      border: none;
    }

    .site-nav .flyout-content a {
      border-bottom-width: 1px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }
  }
}
