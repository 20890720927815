/*.breadcrumb {
  position: relative;
  margin-bottom: 1.5 * $spacer;

  @include borders($breadcrumb-borders);
}*/

.breadcrumb {  
  padding-left: 20px;
  padding-top: 10px;
  background-color: white;
  width: 100%;
  @include borders($breadcrumb-borders);

  li {
    line-height: $breadcrumb-line-height;
  }
}

// .breadcrumb-expanded {
//   width: calc(100% - #{$sidebar-compact-width});
// }

// .breadcrumb-compact {
//   width: calc(100% - #{$sidebar-width});
// }

.breadcrumb-component {
  height: $breadcrumb-height;
  padding: $breadcrumb-padding-y 0rem;
}


// @include media-breakpoint-down(md) {
//   .breadcrumb {
//     width: 100%;
//   }
// }
