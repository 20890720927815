.breadcrumb-fixed {
  /*position: relative;*/
  position: fixed;
  width: 80%;
  z-index: 400;
  @include borders($breadcrumb-borders);
}

.breadcrumb {
  z-index: 2;
  padding:  $breadcrumb-padding-y $breadcrumb-padding-x;
  margin-bottom: 0px;
  height: $breadcrumb-height;
  list-style: none;
  display: inline-block;
  vertical-align: middle;
  background-color: $breadcrumb-bg;
  @include border-radius($border-radius);
  @include clearfix;
  // -webkit-box-shadow: 0 6px 28px -6px #888;
  // box-shadow: 0 6px 28px -6px #888;

  h2 {
    color: $theme-color;
  }

  .subtitle {
    font-weight: bold;
  }

  .mat-form-field {
    bottom: 0;

    .mat-form-field-infix {
      border: 0px solid transparent;
    }
  }
}

.breadcrumb-item {
  float: left;
  // The separator between breadcrumbs (by default, a forward-slash: "/")
  + .breadcrumb-item::before {
    display: inline-block; // Suppress underlining of the separator in modern browsers
    padding-right: $breadcrumb-item-padding;
    padding-left: $breadcrumb-item-padding;
    color: $breadcrumb-divider-color;
    content: "#{$breadcrumb-divider}";
  }
  // IE9-11 hack to properly handle hyperlink underlines for breadcrumbs built
  // without `<ul>`s. The `::before` pseudo-element generates an element
  // *within* the .breadcrumb-item and thereby inherits the `text-decoration`.
  //
  // To trick IE into suppressing the underline, we give the pseudo-element an
  // underline and then immediately remove it.
  + .breadcrumb-item:hover::before {
    text-decoration: underline;
  }

  + .breadcrumb-item:hover::before {
    text-decoration: none;
  }

  &.active {
    color: $breadcrumb-active-color;
  }
}

.printing-option {
  float: right !important;
  padding-left: 10px;
  align-items: center;

  .mat-raised-button {
    line-height: $breadcrumb-line-height;
  }
}
