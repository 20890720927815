// h2 {
//   @apply text-2xl font-semibold mt-4 mb-2;
// }

.navbar {
  .nav-search {
    position: relative;
    top: -1px;

    i {
      position: absolute;
      right: 5px;
      padding: 10px;
      cursor: pointer;
    }

    input {
      border-radius: 30px;
      padding-right: 30px;
      border-color: #ccd0d4;
    }
  }

  .nav-item {
    font-weight: bold;
  }

  .form-inline {
    margin-top: 1px;

    .input-group {
      float: none;
    }

    .btn {
      vertical-align: top;
      border: 0;
    }

    .form-control {
      float: none !important;
      color: $text-color;
      vertical-align: top;
      background-color: $navbar-bg;
      margin-left: -2px;

      &::placeholder {
        color: $text-color;
      }
    }

    .form-control::-webkit-input-placeholder {
      color: $text-color;
    }

    .form-control:-moz-placeholder {
      color: $text-color;
    }

    .form-control::-moz-placeholder {
      color: $text-color;
    }

    .form-control:-ms-input-placeholder {
      color: $text-color;
    }

    .input-group-btn {
      height: 40px;
      background-color: $navbar-bg;
      padding: 5px;

      .btn {
        // margin: 2px;
        color: $text-color;
        background-color: darken($gray-dark, 10%);

        &.dropdown-toggle {
          background-color: $gray;
        }
      }
    }
  }

  .avatar {
    img {
      width: 34px;
      border-radius: 0 !important;
    }
  }
}

.aside-menu .nav.nav-tabs {
  background-color: $gray-lighter;
}

.breadcrumb {
  border-radius: 0;

  h2 {
    font-size: 1.8rem;
    margin-bottom: 0.3rem;
  }

  h4 {
    color: $text-color-light;
    font-size: 1rem;
    margin-bottom: 0.2rem;
  }
}

.card-block-lighter {
  background-color: $gray-lighter;
}

// Unset the _reboot style that messes with the color of anchors lacking an href attribute
a:not([href]):not([tabindex]) {
  color: unset;
  cursor: pointer;

  @include hover-focus {
    color: unset;
  }
}

a.dropdown-item:active:not([href]):not([tabindex]) {
  color: #fff;

  @include hover-focus {
    color: #fff;
  }
}

// Per James, sidebar to perform similarly to Pluralsight. Should ease in/out on hover, and the page content should not move. The sidebar should just go over top of page content.
// Fix animation for sidebar nav
.sidebar-nav {
  transition: margin-left $layout-transition-speed, margin-right $layout-transition-speed, width $layout-transition-speed, flex $layout-transition-speed;
}

// To properly transition the sidebar smoothly, the text cannot be toggle on/off, it must always occupy its space, so the nav must be wide enough to
// accomodate it without wrapping. The sidebar wrapper will hide overflow.
@include media-breakpoint-up(lg) {
  .sidebar-compact {
    .sidebar {
      .nav {
        width: calc(var(--sidebar-width) - 1px);
      }
    }
  }
}


// Image map areas need to display a pointer. Still doesn't work with Edge though, because Edge sucks.
area:not([href]) {
  display: block;
  cursor: pointer;
}

// Page content not to move on expanding sidebar
.sidebar-fixed {
  .main {
    margin-left: $sidebar-compact-width;
  }
}

.sidebar-fixed.sidebar-toggled {
  .main {
    margin-left: var(--sidebar-width);
  }
}

.sidebar-fixed .sidebar {
  z-index: 480;
}

// Have the text fade and slide in
@include media-breakpoint-up(lg) {
  .sidebar-nav span {
    margin-left: 0px;
    opacity: 1;
    transition: margin-left $layout-transition-speed, opacity $layout-transition-speed;
  }

  .sidebar-compact .sidebar-nav span {
    margin-left: -10px;
    opacity: 0;
  }

  .sidebar-fixed .sidebar:hover {
    box-shadow: 5px 0px 50px 0px rgba(0, 0, 0, 0.2);
  }

  .sidebar-toggled .sidebar:hover {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  }
}


@include media-breakpoint-down(md) {
  .app-header {
    .navbar-toggler {
      color: $text-color;
    }
  }
}

.header-fixed .app-header {
  z-index: 402;
}

.app-header.navbar .navbar-brand {
  border-bottom: none;
}

.app-body {
  overflow-x: visible;
}

.app {
  //font-family: sans-serif;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.sidebar-mobile-show {
  .main {
    margin-right: 0 !important;
  }
}

.app-body .main {
  /*min-width: 900px;*/
}

.form-group label {
  font-weight: bold;
}

// Tasks Sidebar
.task-sidebar {
  display: block;
  position: fixed;
  right: 0px;
  top: 0px;
  overflow-y: auto;
  width: $task-sidebar-width;
  max-height: 100%;
  transform: translateX($task-sidebar-width);
  box-shadow: 0px 0px 10px 2px rgba(53, 53, 42, 0.5);
  z-index: 401;
}

.task-sidebar > .card {
  margin-top: var(--navbar-height);
  margin-bottom: 0px;
}

.task-sidebar-toggler {
  position: fixed;
  top: 60px;
  top: 40%;
  right: 0px;
  text-align: center;
  padding-top: 5px;
  background-color: var(--sidebar-bg);
  box-shadow: 0px 0px 10px 2px rgba(53, 53, 42, 0.5);
  z-index: 20;
}

.task-sidebar-toggler a i {
  padding: 10px;
  color: #fff;
}

.task-sidebar-toggler span.badge-warning {
  position: absolute;
  top: 5px;
  left: 50%;
  color: #fff;
  background-color: darken($brand-warning, 5%);
}

.task-sidebar-toggler span.badge-danger {
  position: absolute;
  top: 50px;
  left: 50%;
  color: #fff;
  background-color: $brand-danger;
}

.task-sidebar-toggler #task-sidebar-chevron, .task-sidebar-toggler .fa-exclamation-triangle {
  font-size: 1.8em;
}

.task-sidebar-toggler:hover {
  background-color: var(--sidebar-bg-darken-5);
}

.has-future-tasks {
  background-color: rgb(134, 255, 165);
}

.has-overdue-tasks {
  background-color: rgb(255, 122, 122);
}

// component header
.component-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: $breadcrumb-padding-y $breadcrumb-padding-x;
  margin-bottom: 1.5 * $spacer;
  //@include borders($breadcrumb-borders);
  background-color: transparent; //$breadcrumb-bg;
}

.header-breadcrumbs {
  flex: 0 0 auto;
}

.header-navigation {
  flex: 0 0 auto;
  z-index: 405;
  margin-top: -5px;
}

.header-breadcrumbs h2 {
  font-size: 1.6rem;
  margin-bottom: 0.3rem;
}

.header-breadcrumbs h2 a {
  font-size: 1.4rem;
}

.header-breadcrumbs h4 {
  color: $text-color-light;
  font-size: 0.9rem;
}

.status-new {
  // border: 1px solid #ddd;
  // border-radius: 6px;
  // background-color: #004E98;
  // color: #fff;
  font-weight: bold;
  // padding: 2px 13px;
}

.status-in-progress {
  // border: 1px solid rgb(152, 73, 0);
  // border-radius: 6px;
  // color: #000;
  // padding: 3px;
}

.status-merged {
  // border: 1px solid rgb(122, 0, 152);
  // border-radius: 6px;
  // color: #000;
  // padding: 3px;
}

.status-closed {
  // border: 1px solid #ddd;
  // border-radius: 6px;
  // color: #000;
  // padding: 3px;
}

.status-completed {
  // border: 1px solid rgb(0, 138, 30);
  // border-radius: 6px;
  // color: #000;
  // padding: 3px;
}

.status-not-completed {
  // border: 1px solid rgb(182, 29, 29);
  // border-radius: 6px;
  // color: #000;
  // font-weight: bold;
  // padding: 2px;
}

.status-unread {
  font-weight: bold;
}

.status-read {
  font-weight: normal;
}

.detail-heading {
  font-weight: bold;
  font-size: 16px;
}

.detail-row {
  padding: 4px;
}

.detail-row:nth-child(even) {
  background-color: rgb(248, 248, 248);
  border-top: 1px solid rgb(233, 233, 233);
  border-bottom: 1px solid rgb(233, 233, 233);
}

.detail-row-clean {
  padding: 6px;
}

.detail-label {
  font-size: 14px;
  font-weight: bold;
}

.detail-value {
  font-size: 14px;
}

// Email
.form-table {
  width: 100%;
  margin-left: 8px;
  padding-bottom: 8px;
  margin-bottom: 4px;

  td {
    padding: 8px;
  }

  .row-name {
    text-align: right;
    font-weight: bold;
    width: 80px;
  }

  .row-data {
  }

  .control-col {
    width: 125px;
  }
}

.shadeGrey {
  background-color: rgb(248, 248, 248);
}

.email-toolbar {
  margin: 10px 0 10px 20px;
  color: #004E98;

  a {
    margin-right: 5px;
    font-size: 14px;
    font-weight: 600;

    &:hover {
      text-decoration: none;
    }
  }
}

.remove-btn {
  background-color: #003c70;
  width: 12px;
  height: 12px;
  color: rgb(252, 252, 252);
  border: none;
  font-size: 8px;
  padding: 0;
  text-align: center;
}

.inline-email-data {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 0;
  padding: 0;

  li {
    display: block;
    flex: 0 1 auto; /* Default */
    list-style-type: none;
    margin-left: 5px;
    width: auto;

    a, span {
      font-size: 10px;
      vertical-align: middle;

      &:hover {
        color: #004E98 !important;
      }
    }
  }
}

.lead-detail {
  margin: 0 !important;
  padding: 10px 18px;

  .row {
    margin-bottom: 6px;
  }

  .detail-name {
    font-size: 12px;
    padding: 0;
  }

  .detail-text {
    font-size: 12px;
    padding: 0;
  }
}

.lead-detail .row:nth-child(odd) {
  background-color: rgb(248, 248, 248);
}

.lead-concierge {
  min-width: 35vw;
  min-height: 15vw;
  padding: 10px 18px;

  mat-dialog-content {
      padding: 0px 35px;
  }
}

.inline-ul {
  li {
    display: inline;
    list-style-type: none;
    padding: 4px 6px 4px 2px;
  }
}

.merge-lead-modal-table {
  width: 100%;

  tr:nth-child(odd) {
    background-color: rgb(248, 248, 248);
  }
}

.map-fields-table {
  width: 100%;

  tr:nth-child(odd) {
    background-color: rgb(248, 248, 248);
  }

  td {
    padding: 6px;
  }
}

// Data grids
.datatable {
  .datatable-header-cell {
    padding: 6px;
    font-weight: bold;
  }

  .datatable-row-wrapper:nth-child(odd) {
    background-color: rgb(248, 248, 248);
    border-top: 1px solid rgb(233, 233, 233);
    border-bottom: 1px solid rgb(233, 233, 233);
  }

  .datatable-body-row {
    padding: 6px !important;
  }

  .datatable-body-cell {
    a {
      text-decoration: underline;
      color: #000;

      &:hover {
        text-decoration: underline;
      }
    }

    li a {
      text-decoration: none;
      color: #000;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.ngx-datatable .datatable-body-cell {
  display: flex !important;
  align-items: center;
}

/*.pagination {
  margin: -1px 0 0 0;
  list-style: none;

  li {
    width: 26px;
    height: 26px;
    text-align: center;
    border-radius: 5px;
    border: 1px solid #ddd;
    background-color: #fff;
    margin-left: 4px !important;
    margin-right: 4px !important;

    a {
      text-decoration: none;
      color: #000;
      width: 26px;
      height: 26px;
      text-align: center;
      padding: 4px 0 0 0;
      line-height: 1;
    }
  }

  li.active {
    background-color: #003c70;

    a {
      color: #fff;
    }
  }
}*/

.datatable-footer {
  padding-top: 20px;
}

// Modals
.modal-xl {
  max-width: 90% !important;
  max-height: 800px !important;
}

// Ngx Datatable Overrides
.small-result {
  min-height: 210px;
}

.ngx-datatable .datatable-body-cell, .ngx-datatable .datatable-header-cell {
  // Override datatables style so that buttons with dropdowns can be used in cells.
  overflow-x: visible !important;
}

.ngx-datatable .datatable-body-cell li {
  list-style: none;
}

.ngx-datatable .datatable-body-cell li i {
  color: $text-color;
}

.action-menu-ul-horizontal {
  left: -211px !important;
  top: -4px !important;
  padding: 0;
}

.action-menu-tbl {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  border: 1px solid #ddd;
  padding: 0;
  margin: 0;

  tr:not(:last-child) {
    border-bottom: 1px solid #ddd;
  }

  td:nth-child(odd) {
    border-right: 1px solid #ddd;
  }

  a {
    height: 24px;
    padding: 0 14px;
    margin: 0;
  }

  li {
    height: 24px;
    padding: 0;
    margin: 0;
    border-bottom: 1px solid #ddd;
  }
}

.action-menu {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  border: 1px solid #ddd;
  width: 50px;
  // box-shadow: 0 0 0 1px rgba(204, 204, 204, 0.5);
  a {
    height: 24px;
    padding: 0 20px;
  }
}

.action-menu-down {
  left: -159px !important;
  top: -2px !important;
}

.action-menu-up {
  left: -159px !important;
  top: -75px !important;
}

.action-menu-horizontal {
  a {
    display: inline-block;
  }
}

.ld-action-menu-down {
  left: -159px !important;
  top: -3px !important;
}

.ld-action-menu-up {
  left: -159px !important;
  top: -52px !important;
}

// .action-menu-btn {
//     width: 50px;
//     height: 25px;
//     z-index: 9999999999999 !important;
//     border: 1px solid #ddd;
//     cursor: pointer;
//     background-color: #fff !important;
// }

.action-menu-open-btn {
  // border-bottom: none !important;
}

.action-menu-open-icon {
  color: #004E98;
}

// Impersonation
.impersonating {
  background-color: rgb(242,196,196);
}

// Custom Checkbox
/* Customize the label (the container) */
.custom-checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 0px !important;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.custom-checkbox-container input {
  position: relative;
  opacity: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 18%;
  left: 20px;
  height: 18px;
  width: 18px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.custom-checkbox-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.custom-checkbox-container input:checked ~ .checkmark {
  background-color: rgb(4, 201, 86);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.custom-checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.custom-checkbox-container .checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.time-menu {
  border: 1px solid #0275d8;
  padding: 5px;
  width: 190px;
  z-index: 99999;
  position: absolute;
  background-color: #fff;
}

// Leads Search Page Tabs
#lead-search-tabs ul li:last-child {
  position: absolute;
  right: 10px;
  top: -4px;
}

// Search Results
.search-header {
  padding: 12px;
  border-bottom: 2px solid #ddd;

  .result-text {
    margin-right: 20px;
    font-size: 18px;
    font-weight: 600;
  }

  .search-form-container {
    position: relative;
    top: -1px;

    i {
      position: absolute;
      right: 5px;
      padding: 10px;
      cursor: pointer;
    }

    input {
      width: 290px;
      padding-right: 30px;
      border-radius: 10px;
      border-color: #ccd0d4;
    }
  }
}

// Options

.li-option {
  cursor: pointer;

  &:hover {
    background-color: #ddd !important;
  }
}

.impersonate-search-header {
  // padding: 10px;
  .result-text {
    margin-right: 20px;
    font-size: 30px;
    font-weight: 600;
  }

  .search-form-container {
    position: relative;
    margin-top: 10px;

    i {
      position: absolute;
      right: 30px;
      padding: 10px;
      cursor: pointer;
    }

    input {
      width: 290px;
      padding-right: 30px;
      border-radius: 10px;
      border-color: #ccd0d4;
    }
  }
}

.long-dropdown-menu {
  height: 250px;
  overflow-y: auto;
}

.disabled-select {
  background-color: #ddd !important;
}

.container-breadcrumb {
  margin-top: 70px;
}

.bs-datepicker {
  z-index: 5 !important;
}

.table-report {
  th {
    cursor: pointer;
  }

  a, a:hover {
    color: $text-color;
    text-decoration: underline;
  }
}

.table-pagination {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}

.card-accent-primary {
  border-top-color: $theme-color;
}

.card-deck {
  margin-bottom: 15px;
}

.eula-body {

  .eula-header {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 6px;
  }

  .eula-content {
    font-weight: 500;

    .section-title {
      font-weight: bold;
    }

    .section {
      margin-left: 10px;

      .section-item {
        // padding: 10px 2px;
      }
    }
  }
}

.trend-up {
  color: $green;
}

.trend-down {
  color: $red;
}

.trend-neutral {
  color: $yellow;
}

/*.table-fixed thead {
  width: 97%;
  margin-top: -21px;
}
.table-fixed tbody {
  height: 259px;
  overflow-y: auto;
  width: 100%;
}
.table-fixed thead, .table-fixed tbody, .table-fixed tr, .table-fixed td, .table-fixed th {
  display: block;
}
.table-fixed tbody td, .table-fixed thead > tr> th {
  float: left;
  white-space: nowrap;
  overflow: hidden;
  border-bottom-width: 0;
}
  */
.col-width-1 {
  width: 1% !important;
}

.col-width-2 {
  width: 2% !important;
}

.col-width-3 {
  width: 3% !important;
}

.col-width-4 {
  width: 4% !important;
}

.col-width-5 {
  width: 5% !important;
}

.col-width-6 {
  width: 6% !important;
}

.col-width-7 {
  width: 7% !important;
}

.col-width-8 {
  width: 8% !important;
}

.col-width-9 {
  width: 9% !important;
}

.col-width-10 {
  width: 10% !important;
}

.col-width-11 {
  width: 11% !important;
}

.col-width-12 {
  width: 12% !important;
}

.col-width-13 {
  width: 13% !important;
}

.col-width-14 {
  width: 14% !important;
}

.col-width-15 {
  width: 15% !important;
}

.col-width-16 {
  width: 16% !important;
}

.col-width-17 {
  width: 17% !important;
}

.col-width-18 {
  width: 18% !important;
}

.col-width-19 {
  width: 19% !important;
}

.col-width-20 {
  width: 20% !important;
}

.col-width-21 {
  width: 21% !important;
}

.col-width-22 {
  width: 22% !important;
}

.col-width-23 {
  width: 23% !important;
}

.col-width-24 {
  width: 24% !important;
}

.col-width-25 {
  width: 25% !important;
}

.col-width-26 {
  width: 26% !important;
}

.col-width-27 {
  width: 27% !important;
}

.col-width-28 {
  width: 28% !important;
}

.col-width-29 {
  width: 29% !important;
}

.col-width-30 {
  width: 30% !important;
}

.col-width-31 {
  width: 31% !important;
}

.col-width-32 {
  width: 32% !important;
}

.col-width-33 {
  width: 33% !important;
}

.col-width-34 {
  width: 34% !important;
}

.col-width-35 {
  width: 35% !important;
}

.col-width-36 {
  width: 36% !important;
}

.col-width-37 {
  width: 37% !important;
}

.col-width-38 {
  width: 38% !important;
}

.col-width-39 {
  width: 39% !important;
}

.col-width-40 {
  width: 40% !important;
}

.col-width-41 {
  width: 41% !important;
}

.col-width-42 {
  width: 42% !important;
}

.col-width-43 {
  width: 43% !important;
}

.col-width-44 {
  width: 44% !important;
}

.col-width-45 {
  width: 45% !important;
}

.col-width-46 {
  width: 46% !important;
}

.col-width-47 {
  width: 47% !important;
}

.col-width-48 {
  width: 48% !important;
}

.col-width-49 {
  width: 49% !important;
}

.col-width-50 {
  width: 50% !important;
}

.col-width-51 {
  width: 51% !important;
}

.col-width-52 {
  width: 52% !important;
}

.col-width-53 {
  width: 53% !important;
}

.col-width-54 {
  width: 54% !important;
}

.col-width-55 {
  width: 55% !important;
}

.col-width-56 {
  width: 56% !important;
}

.col-width-57 {
  width: 57% !important;
}

.col-width-58 {
  width: 58% !important;
}

.col-width-59 {
  width: 59% !important;
}

.col-width-60 {
  width: 60% !important;
}

.col-width-61 {
  width: 61% !important;
}

.col-width-62 {
  width: 62% !important;
}

.col-width-63 {
  width: 63% !important;
}

.col-width-64 {
  width: 64% !important;
}

.col-width-65 {
  width: 65% !important;
}

.col-width-66 {
  width: 66% !important;
}

.col-width-67 {
  width: 67% !important;
}

.col-width-68 {
  width: 68% !important;
}

.col-width-69 {
  width: 69% !important;
}

.col-width-70 {
  width: 70% !important;
}

.col-width-71 {
  width: 71% !important;
}

.col-width-72 {
  width: 72% !important;
}

.col-width-73 {
  width: 73% !important;
}

.col-width-74 {
  width: 74% !important;
}

.col-width-75 {
  width: 75% !important;
}

.col-width-76 {
  width: 76% !important;
}

.col-width-77 {
  width: 77% !important;
}

.col-width-78 {
  width: 78% !important;
}

.col-width-79 {
  width: 79% !important;
}

.col-width-80 {
  width: 80% !important;
}

.col-width-81 {
  width: 81% !important;
}

.col-width-82 {
  width: 82% !important;
}

.col-width-83 {
  width: 83% !important;
}

.col-width-84 {
  width: 84% !important;
}

.col-width-85 {
  width: 85% !important;
}

.col-width-86 {
  width: 86% !important;
}

.col-width-87 {
  width: 87% !important;
}

.col-width-88 {
  width: 88% !important;
}

.col-width-89 {
  width: 89% !important;
}

.col-width-90 {
  width: 90% !important;
}

.col-width-91 {
  width: 91% !important;
}

.col-width-92 {
  width: 92% !important;
}

.col-width-93 {
  width: 93% !important;
}

.col-width-94 {
  width: 94% !important;
}

.col-width-95 {
  width: 95% !important;
}

.col-width-96 {
  width: 96% !important;
}

.col-width-97 {
  width: 97% !important;
}

.col-width-98 {
  width: 98% !important;
}

.col-width-99 {
  width: 99% !important;
}

.col-width-100 {
  width: 100% !important;
}

label.mat-button-toggle-label {
  display: inline !important;
}


.user-bar-nav {
  a:not([href]):not([tabindex]) {
    color: $text-color;

    @include hover-focus {
      color: $text-color;
    }
  }

  i {
    color: black;
  }

  .dropdown-header {
    padding: 8px 20px;
    background: $navbar-bg; //$dropdown-divider-bg;
    border-bottom: 1px solid $dropdown-border-color;

    .btn {
      margin-top: -7px;
      color: $dropdown-header-color;

      &:hover {
        color: $body-color;
      }

      &.pull-right {
        margin-right: -20px;
      }
    }
  }
}

.disclaimer {
  padding-left: 30px;
}

/* Styles for Quill Editor */

.ql-edit-html-textarea {
  width: 100%;
  background: rgb(29, 29, 29);
  color: rgb(204, 204, 204);
  outline: none;
  padding: 12px 15px;
  font-family: Consolas;
  position: absolute;
  top: 0;
  bottom: 0;
  border: none;
}

