.trafficSummary, .trafficDetail {
  
  .uneditable-input {
    border: none;
    /*border-left: 1px solid $white;
    border-radius: 0px;
    height: 33px;
    background-color: var(--brand-primary);
    color: $white;
    min-width: 150px;
    display: inline-block;
    text-align: center;

    i {
      padding-top: 4px;
    }

    &:hover {
      cursor: pointer;
      background-color: rgb(35, 93, 146);
    }*/
  }
}
