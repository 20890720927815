.legacy-billboard-title {
  font-size: 20px !important;
  text-transform: uppercase;
}

.legacy-billboard-low-score {
  background: #0d4266 !important;
  color: #ffffff !important;
}
.legacy-billboard-medium-score {
  background: #2274ac !important;
  color: #ffffff !important;
}
.legacy-billboard-high-score {
  background: #0d4266 !important;
  color: #ffffff !important;
}

.legacy-provider-scorecard {
  padding: 0px 10px;
  margin: 10px auto;
}

  .legacy-provider-scorecard-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, 275px);
    grid-gap: 1rem;
    justify-content: space-around;
    padding-bottom: 10px;

    .legacy-provider-scorecard {
      padding: 10px;
      margin: 10px auto;

      img {
        border: 1px solid #cccccc;
        border-radius: 5px;
        width: 100%;
      }

      .bottom-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #6f6f6f;
        width: 100%;
        font-size: 1.1em;
        margin-top: 10px;
        padding: 0 5px;

        b {
          flex: 1 auto;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          padding-right: 1em;
        }

        a {
          color: #6f6f6f;
        }
      }
    }
  }
