.app-header.navbar {
  top: 0px;
  position: relative;
  flex-direction: row;
  height: var(--navbar-height);
  margin-left: 0;
  padding: 0;
  background-color: $navbar-bg;
  @include borders($navbar-border);

  .navbar-brand {
    display: inline-block;
    width: var(--navbar-brand-width);
    height: var(--navbar-brand-height);
    padding: $navbar-padding-y $navbar-padding-x;
    margin-left: var(--navbar-brand-margin-left);
    /* background-color: $navbar-brand-bg; */
    background-image: var(--navbar-brand-logo);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: var(--navbar-brand-logo-size);
    border-bottom: #34383c;
    @include borders($navbar-brand-border);
  }

  .navbar-nav {
    flex-direction: row;
    align-items: center;
  }

  @media screen and (max-width: 992px) {
    .navbar-brand {
      background-image: var(--navbar-brand-logo-mobile);
    }
  }

  @media screen and (max-width: 600px) {
    .navbar-nav {
      height: auto !important;
      flex-direction: column !important;
      align-items: flex-start !important;
    }
  }

  .nav-item {
    position: relative;
    min-width: 50px;
    margin: 0 !important;
    text-align: center;

    button {
      margin: 0 auto;
    }

    .nav-link {
      padding-top: 0;
      padding-bottom: 0;
      border: 0;
      background: 0;

      .badge {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -16px;
        margin-left: 0;
      }

      >.img-avatar {
        height: var(--navbar-height) - 20px;
        margin: 0 10px;
      }
    }
  }

  .dropdown-menu {
    padding-bottom: 0;
    line-height: $line-height-base;
  }

  .dropdown-item {
    min-width: 180px;
  }
}

.navbar-brand {
  color: $navbar-active-color;

  @include hover-focus {
    color: $navbar-active-color;
  }
}

.navbar-nav {
  .nav-link {
    color: $navbar-color;

    @include hover-focus {
      color: $navbar-hover-color;
    }
  }

  .open>.nav-link,
  .active>.nav-link,
  .nav-link.open,
  .nav-link.active {
    @include plain-hover-focus {
      color: $navbar-active-color;
    }
  }
}

.navbar-divider {
  background-color: rgba(0, 0, 0, .075);
}