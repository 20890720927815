.secondary-nav-container {
  background-color: white;
  border-right: 1px solid $border-color;
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  // **** Modified from _sidebar.scss ****
  overflow-x: hidden;
  overflow-y: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -webkit-box-shadow: 2px 0px 12px -6px #888;
  box-shadow: 2px 0px 12px -6px #888;

  &::-webkit-scrollbar {
    position: absolute;
    width: 10px;
    margin-left: -10px;
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar-track {
    background-color: lighten($gray-lighter, 5%);
    border-right: 1px solid darken($gray-lighter, 5%);
    border-left: 1px solid darken($gray-lighter, 5%);
  }

  &::-webkit-scrollbar-thumb {
    height: 50px;
    background-color: darken($gray-lighter, 10%);
    background-clip: content-box;
    border-color: transparent;
    border-style: solid;
    border-width: 1px 2px;
  }
  // *************************************
  .secondary-nav {

    .divider {
      padding-left: 5%;
      padding-right: 5%;
      width: 100%
    }

    .nav {
      list-style: none;
      margin-top: 10px;

      .nav-item {
        width: 100%;

        .nav-link {
          padding: 0.5rem 10%;
          border-left: 3px solid transparent;
          color: $gray-dark;

          table {
            width: 100%;

            .td-icon {
              width: 20%;
              text-align: center;
            }

            .td-name {
              width: 80%;
              text-align: left;
            }
          }

          &.active {
            background-color: $secondary-nav-active-color;
            border-left: 3px solid $theme-color;

            i {
              color: $theme-color;
            }
          }

          &:hover {
            background-color: $secondary-nav-hover-color;
          }
        }
      }
    }
  }
}
