@media print {
    .app-footer {
        display: none;
    }

    .breadcrumb {
        display: none;
    }

    .sidebar {
        display: none;
    }

    .task-sidebar-toggler, .task-sidebar {
        display: none;
    }

    .app-header {
        display: none;
    }

    .container-breadcrumb {
        margin-top: 0px;
    }
}

// analytics pdf generator styles

.print-mode-navbar {
    height: 0px !important;
}

.print-mode-filter-bar {
    margin-top: 0px !important;
}


.print-mode-report {
    z-index: 999999999999;
    margin-top: 0px !important;
    margin-left: -5px;
    margin-bottom: 0px;
    background-color: white;
    height: 100vh !important;
    margin: 0px !important;

    .clear-filter-button {
        display: none;
    }

    .filter-bar {
        margin-top: 0px !important;
    }

    .router-container {
        margin-top: 0px !important;
    }

    .print-table {
        page-break-inside: auto;
    }

    .content-container {
        background-color: white !important;
        margin-top: 0px !important;
        padding-top: 10px !important;
        height: 100% !important;
    }

    .reportContainer {
        height: unset !important;
    }

    .print-report-header {
        visibility: visible;
        height: 100px;
        background-color: #fff;
        display: show;
        padding-left: 45px;
    }
    .print-report-title {
        font-size: 40px;
        font-weight: bold;
        color: var(--brand-primary);
    }
    .print-report-breadcrumb {
        font-size: 20px;
        font-weight: bold;
        color: black;
        display: show !important;
    }

    .print-report-logo {
        height: 50px;
        margin-top: -50px;
        margin-left: -130px;
    }

    .mat-card, .card-header {
        border: 1px solid !important;
        border-color: rgba(0, 0, 0, 0.12) !important;
    }
}