/*
 *  Usage:
 *
      <div class="sk-circle">
        <div class="sk-circle1 sk-child"></div>
        <div class="sk-circle2 sk-child"></div>
        <div class="sk-circle3 sk-child"></div>
        <div class="sk-circle4 sk-child"></div>
        <div class="sk-circle5 sk-child"></div>
        <div class="sk-circle6 sk-child"></div>
        <div class="sk-circle7 sk-child"></div>
        <div class="sk-circle8 sk-child"></div>
        <div class="sk-circle9 sk-child"></div>
        <div class="sk-circle10 sk-child"></div>
        <div class="sk-circle11 sk-child"></div>
        <div class="sk-circle12 sk-child"></div>
      </div>
 *
 */
 @use "sass:math";
 @import "../variables";

.sk-circle {
  $circleCount: 12;
  $animationDuration: 1.2s;

  margin: $spinkit-spinner-margin;
  width: $spinkit-size;
  height: $spinkit-size;
  position: relative;

  .sk-child {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  .sk-child:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: $spinkit-spinner-color;
    border-radius: 100%;
    animation: sk-circleBounceDelay $animationDuration infinite ease-in-out both;
  }

  @for $i from 2 through $circleCount {
    .sk-circle#{$i} { transform: rotate(math.div(360deg,$circleCount) * ($i - 1)); }
  }

  @for $i from 2 through $circleCount {
    .sk-circle#{$i}:before { animation-delay: - $animationDuration + math.div($animationDuration,$circleCount) * ($i - 1); }
  }
}

@keyframes sk-circleBounceDelay {
  0%, 80%, 100% { transform: scale(0) }
  40% { transform: scale(1.0) }
}
