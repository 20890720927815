.resource-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, 275px);
  grid-gap: 1rem;
  justify-content: space-around;
  padding-bottom: 10px;

  .resource-download {
    padding: 0 10px;
    margin: 10px auto;

    img {
      border: 1px solid #cccccc;
      border-radius: 5px;
      width: 100%;
    }

    .bottom-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #6f6f6f;
      width: 100%;
      font-size: 1.1em;
      margin-top: 10px;
      padding: 0 5px;

      b {
        flex: 1 auto;
        padding-right: 1em;
      }

      a {
        color: #6f6f6f;
      }
    }
  }
}
