@use "sass:math";

.flyout-menu-backdrop+* .cdk-overlay-pane {
  margin-left: 44px;
}

.flyout-menu-backdrop+* .cdk-overlay-connected-position-bounding-box {
  margin-left: 44px;
}

.flyout-menu-backdrop {
  margin-left: 44px;
}


.flyout-menu {
  // margin-left: 44px;
  margin-top: -30px;
  // removes black border on the left of menu
  overflow: unset !important;

  button {
    outline: none;
    top: 0;
  }

  .flyoutlink {
    text-decoration: none;
    color: black !important;
    line-height: 48px;
    font-size: 15px;
  }

  .flyout-menu-title {
    position: relative;
    text-align: center;
    outline: none;

    i {
      color: var(--brand-primary);
    }

    span {
      color: black;
    }
  }

  .flyoutmenuitem:hover {
    background-color: #f5f5f5;
  }

  -ms-overflow-style: -ms-autohiding-scrollbar;

  &::-webkit-scrollbar {
    position: absolute;
    width: 10px;
    margin-left: -10px;
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar-track {
    background-color: var(--sidebar-bg-lighten-5);
    border-right: 1px solid var(--sidebar-bg-darken-5);
    border-left: 1px solid var(--sidebar-bg-darken-5);
  }

  // &::-webkit-scrollbar-track-piece {    }

  &::-webkit-scrollbar-thumb {
    height: 50px;
    background-color: var(--sidebar-bg-darken-10);
    background-clip: content-box;
    border-color: transparent;
    border-style: solid;
    border-width: 1px 2px;
  }
}

// .sidebar-toggle-icon {
//   font-size: 40px;
//   color: #fff;
// }

@media (min-width: 992px) {
  .sidebar-toggle {
    width: 100%;
    height: 44px;
    padding-bottom: 4px;
    padding-top: 5px;
    bottom: 0px;
    cursor: pointer;
    background: var(--sidebar-bg);
    border-right: 1px solid #cccccc;
    user-select: none;
  }

  .sidebar-toggle-icon {
    font-size: 40px;
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    outline: none;
  }
}

@media (max-width: 991px) {
  .sidebar-toggle {
    display: none !important;
  }
}

// sidebar navigation styles
.sidebar-container {
  height: calc(100vh - 155px);

  .sidebar {
    top: var(--navbar-height);
    padding: $sidebar-padding;
    overflow: hidden;
    color: $sidebar-color;
    background: var(--sidebar-bg);
    @include borders($sidebar-borders);


    .sidebar-menu-closed {
      margin-top: -40px;
      margin-left: 144px;
    }

    .sidebar-menu-open {
      margin-top: -40px;
      margin-left: 214px;
    }

    .sidebar-close {
      position: absolute;
      right: 0;
      display: none;
      padding: 0 $spacer;
      font-size: 24px;
      font-weight: 800;
      line-height: var(--navbar-height);
      color: $sidebar-color;
      background: 0;
      border: 0;
      opacity: .8;

      &:hover {
        opacity: 1;
      }
    }

    .sidebar-nav {
      position: relative;
      overflow-x: hidden;
      overflow-y: auto;
      display: block;
      height: 100%;

      -ms-overflow-style: -ms-autohiding-scrollbar;

      &::-webkit-scrollbar {
        position: absolute;
        width: 10px;
        margin-left: -10px;
        -webkit-appearance: none;
      }

      &::-webkit-scrollbar-track {
        background-color: var(--sidebar-bg-lighten-5);
        border-right: 1px solid var(--sidebar-bg-darken-5);
        border-left: 1px solid var(--sidebar-bg-darken-5);
      }

      &::-webkit-scrollbar-thumb {
        height: 50px;
        background-color: var(--sidebar-bg-darken-10);
        background-clip: content-box;
        border-color: transparent;
        border-style: solid;
        border-width: 1px 2px;
      }
    }

    .nav {
      width: calc(var(--sidebar-width) - 1px);
      flex-direction: column !important;
      display: block;

      .nav-title {
        padding: $sidebar-nav-title-padding-y $sidebar-nav-title-padding-x;
        font-size: 11px;
        font-weight: 600;
        color: $sidebar-nav-title-color;
        text-transform: uppercase;
      }

      .divider {
        height: 10px;
      }

      .nav-item {
        position: relative;
        margin: 0;
        transition: background .3s ease-in-out;

        .sidebar-category {
          display: flex;

          &:hover {
            color: $sidebar-nav-link-hover-color;
            background: $sidebar-nav-link-hover-bg;
          }

          .sidebar-category-icon {
            cursor: pointer;
            margin-right: -20px;
            font-size: 20px;
            user-select: none;
          }

          .sidebar-category-open {
            display: flex;
            width: 100%;

            .sidebar-link {
              white-space: nowrap;
              padding-right: 0px;

              i {
                width: 25px;
                height: 45px;
                margin-top: (-($sidebar-nav-link-padding-y + 0.25rem)) !important;
                margin-bottom: -$sidebar-nav-link-padding-y;
                margin-right: math.div($sidebar-nav-link-padding-x,2);
                line-height: 45px;
                color: $sidebar-nav-link-icon-color;
                border-right: 0px solid $border-color;
              }
            }

            .sidebar-expand {
              width: 100%;
              padding-left: 0px;
              cursor: pointer;

              &.active {
                color: $sidebar-nav-link-active-color;
                background: $sidebar-nav-link-active-bg !important;
                @include borders($sidebar-nav-link-active-borders);
              }

              i {
                cursor: pointer;
                font-size: 20px;
                margin-right: 0px;
              }
            }
          }
        }

        ul {
          max-height: 500;
          padding: 0;
          margin: 0;
          overflow-y: hidden;
          transition: max-height .3s ease-in-out;

          li {
            padding: 0;
            list-style: none;
            &:not(.nav-item-child) {
              background-color: $sidebar-nav-item-color;
            }
            &.nav-item-child {
              background-color: $sidebar-nav-item-child-bg;
            }
          }
        }

        .nav-link {
          display: block;
          padding: $sidebar-nav-link-padding-y $sidebar-nav-link-padding-x;
          color: $sidebar-nav-link-color;
          text-decoration: none;
          outline: none;
          height: 100%;
          padding-right: 0px;

          @include borders($sidebar-nav-link-borders);
          @if $enable-sidebar-nav-rounded {
            border-radius: $border-radius;
          }
          // different font awesome icons need different margins to appear centered - it's an ILLUSION
          .sidebar-icon {
            margin-left: 2px;
          }

          .sidebar-leads-icon {
            margin-left: 1px;
          }

          .sidebar-sales-icon {
            margin-left: 4px;
          }

          &:not(.nav-item-child) {
            background: $sidebar-nav-link-bg;

            &:hover {
              color: $sidebar-nav-link-hover-color !important;
              background: $sidebar-nav-link-hover-bg !important;
              @include borders($sidebar-nav-link-hover-borders);
            }

            &.active {
              color: $sidebar-nav-link-active-color;
              background: $sidebar-nav-link-active-bg !important;
              @include borders($sidebar-nav-link-active-borders);
            }
          }

          &.nav-item-child {
            background: $sidebar-nav-item-child-bg;

            &:hover {
              color: $sidebar-nav-link-hover-color !important;
              background: $sidebar-nav-item-child-hover-bg !important;
              @include borders($sidebar-nav-link-hover-borders);
            }

            &.active {
              color: $sidebar-nav-link-active-color;
              background: $sidebar-nav-item-child-active-bg !important;
              @include borders($sidebar-nav-link-active-borders);
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .sidebar-compact {
    .sidebar {
      .sidebar-nav {
        width: 49px;
      }

      .nav {
        width: 49px;
      }
    }
  }
}
