.sdcard-group {
    display: flex;
    flex-flow: row wrap;
    height: 100%;

    .card {
        flex: 1 0;

        + .card {
            margin-left: 0;
            border-left: 0;
        }
        // Handle rounded corners
        @if $enable-rounded {
            &:first-child {
                @include border-right-radius(0);

                .card-img-top {
                    border-top-right-radius: 0;
                }

                .card-img-bottom {
                    border-bottom-right-radius: 0;
                }
            }

            &:last-child {
                @include border-left-radius(0);

                .card-img-top {
                    border-top-left-radius: 0;
                }

                .card-img-bottom {
                    border-bottom-left-radius: 0;
                }
            }

            &:not(:first-child):not(:last-child) {
                border-radius: 0;

                .card-img-top,
                .card-img-bottom {
                    border-radius: 0;
                }
            }
        }
    }
}

.login-card {
    border-right: none;
}

.logo-holder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    gap: var(--logo-gap);
}

.login-logo {
    padding: 1rem;
    background-image: var(--login-logo);
    background-repeat: no-repeat;
    background-size: var(--logo-bg-size);
    width: var(--login-logo-width);
    height: var(--login-logo-height);
}

.login-brand-logo {
    background-image: var(--login-brand-logo);
    background-repeat: no-repeat;
    background-size: var(--logo-bg-size);
    height: var(--login-brand-logo-height);
    width: var(--login-brand-logo-width);
    max-width: 100%;
}

.app-header.navbar .navbar-brand {
    background-size: var(--navbar-brand-logo-size);
}
