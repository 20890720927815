.mat-form-field {
    &.datatable-trend-toggle {
      width: $datatable-trend-toggle-select-width;
    }
  }
  
  /*checkbox select all*/
  .select-all > .mat-pseudo-checkbox {
    display: none !important;
  }
  
  .custom-select-option {
    height: 48px;
    padding-left: 16px;
    position: relative;
    cursor: pointer;
    outline: 0;
    display: flex;
    flex-direction: row;
    max-width: 100%;
    box-sizing: border-box;
    align-items: center;
  
    &:hover {
      background: rgba(0, 0, 0, 0.04);
      color: rgba(0, 0, 0, 0.87);
    }
  }
  
  .metric-select {
    min-width: 250px;
  }
  
  @include media-breakpoint-down(md) {
    .metric-select {
      width: 100px !important;
      min-width: unset;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 5px;
      padding-left: 0px !important;
    }
  }
  
  @include media-breakpoint-down(sm) {
    .metric-select, .datatable-trend-toggle {
      width: 35px !important;
      min-width: unset;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 5px;
      padding-left: 0px !important;
    }
  }
  