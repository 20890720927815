// Button sizes
@mixin sdbutton-variant($color, $background, $border) {
    $active-background: darken($background, 10%);
    $active-border: darken($border, 12%);

    color: $color;
    background-color: $background;
    border-color: $border;
    @include box-shadow($btn-box-shadow);
    // Hover and focus styles are shared
    @include hover {
        color: $color;
        background-color: $active-background;
        border-color: $active-border;
    }

    &:focus,
    &.focus {
        // Avoid using mixin so we can pass custom focus shadow properly
        @if $enable-shadows {
            box-shadow: $btn-box-shadow, 0 0 0 2px rgba($border, .5);
        }
        @else {
            box-shadow: 0 0 0 2px rgba($border, .5);
        }
    }
    // Disabled comes first so active can properly restyle
    &.disabled,
    &:disabled {
        background-color: $background;
        border-color: $border;
    }

    &:active,
    &.active,
    .show > &.dropdown-toggle {
        color: $color;
        background-color: $active-background;
        background-image: none; // Remove the gradient for the pressed/active state
        border-color: $active-border;
        @include box-shadow($btn-active-box-shadow);
    }
}

// Cards
@mixin card-accent-variant($color) {
    border-top-width: 2px;
    border-top-color: $color;
}

@mixin card-variant($background, $border) {
    background-color: $background;
    border-color: $border;

    .card-header,
    .card-footer {
        background-color: transparent;
    }
}

@mixin card-outline-variant($color) {
    background-color: transparent;
    border-color: $color;
}

//
// Inverse text within a card for use with dark backgrounds
//

@mixin card-inverse {
    color: rgba(255,255,255,.65);

    .card-header,
    .card-footer {
        background-color: transparent;
        border-color: rgba(255,255,255,.2);
    }

    .card-header,
    .card-footer,
    .card-title,
    .card-blockquote {
        color: #fff;
    }

    .card-link,
    .card-text,
    .card-subtitle,
    .card-blockquote .blockquote-footer {
        color: rgba(255,255,255,.65);
    }

    .card-link {
        @include hover-focus {
            color: $card-link-hover-color;
        }
    }
}
