.trafficSummary, .trafficDetail {
  .progress {
    border: 1px solid #d5d5d5;
    border-radius: 0px;
    background-color: rgba(255, 255, 255, 0.00);

    &.referrerquality {
      border: none;
    }
  }

  .progress-label {
    display: inline-block;
  }
}
