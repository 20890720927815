.datatable-row-even {
    
}

.datatable-body-row {
    border-bottom: 1px solid $border-color;
}

.ngx-datatable.material .datatable-header .datatable-header-cell {
    font-weight: bold;
    color: black;
    font-size: 0.875rem;
}

.datatable-body-cell-label {
    flex-grow: 1;
}
