.filter-breadcrumb {

  ul {
    margin: 0px;
    padding: 0px;
    font-weight: bold;

    li {
      list-style-type: none;
      display: inline-block;

      i {
        padding-left: 5px;
        padding-right: 5px;
      }
    }
  }
}
