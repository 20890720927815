.referrer-types {
  padding-top: 0px;
  padding-bottom: 20px;
  height: 375px;
  overflow-y: auto;

  .row {
    padding-top: 5px;

    &.labels {
      /*position: sticky;
      top: 0;
      background-color: white;
      z-index: 1;*/
      padding-top: 10px;
    }
  }

  .border {
    border: 1px solid rgba(0,0,0,0.15);

    table {
      height: 1.5em;
    }
  }

  .metric-display {
    display: inline-block;
    width: 50%;
    text-align: left;
  }
}
